import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import Rails from "@rails/ujs"

// If using a handle class, add `data-sortable-handle-value=".fa-sort"`
// If using a item class, add  data-sortable-item-value=".item"
export default class extends Controller {
  connect() {
    let options = {
      onEnd: this.end.bind(this),
      animation: 150
    }

    const handle = this.data.get('handleValue')
    if (handle) options['handle'] = handle

    const draggable = this.data.get('draggable')
    if (draggable) options['draggable'] = draggable

    this.sortable = Sortable.create(this.element, options)
  }

  end(event) {
    let data = new FormData()
    data.append('position', event.newDraggableIndex)

    Rails.ajax({
      url: event.item.dataset.sortableUrl,
      type: 'PATCH',
      data: data
    })
  }
}
