import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Load models array and initialize.
    this.partModels = JSON.parse(this.data.get('partModels'));
    const modelId = this.data.get('modelId');
    if (modelId != null) {
      this.updatePartModel(modelId)
    }
  }

  updatePartMake() {
    const cat_id = parseInt($('#part_category_id').val());
    if (cat_id == 1) {
      $('#part_make_id').val('1');
      $('#part_model_id').html("<option value='1' selected='selected'>* NOT SPECIFIED</option>");
    } else if (cat_id == 2) {
      $('#part_make_id').val('2');
      $('#part_model_id').html("<option value='2' selected='selected'>* GENERAL AVIATION</option>");
    }
  }

  updatePartModel(id) {
    const model_id = id == null ? null : id;
    const make_id = parseInt($('#part_make_id').val());
    let options = new String();

    for (let i = 0; i < this.partModels.length; i++) {
      if (make_id == this.partModels[i][0]) {
        if (model_id == this.partModels[i][2]) {
          options += '<option value="' + this.partModels[i][2] + '" selected="selected">' + this.partModels[i][1] + '</option>';
        } else {
          options += '<option value="' + this.partModels[i][2] + '">' + this.partModels[i][1] + '</option>';
        }
      }
    }
    $("#part_model_id").html(options);
  }
}
