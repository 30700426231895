import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // Load models array and initialize.
    console.log('update aircraft connected')
    this.aircraftModels = JSON.parse(this.data.get('aircraftModels'));
    const modelId = this.data.get('modelId');
    if (modelId != null) {
      this.updateAircraftModel(modelId);
    }
  }

  updateAircraftMake() {
    const cat_id = parseInt($('#aircraft_category_id').val());
    if (cat_id == 1) {
      $('#aircraft_make_id').val('1');
      $('#aircraft_model_id').html("<option value='1' selected='selected'>* NOT SPECIFIED</option>");
    }
  }

  updateAircraftModel(id) {
    const model_id = id == null ? null : id;
    const make_id = parseInt($('#aircraft_make_id').val());
    let options = new String();

    for (let i = 0; i < this.aircraftModels.length; i++) {
      if (make_id == this.aircraftModels[i][0]) {
        if (model_id == this.aircraftModels[i][2]) {
          options += '<option value="' + this.aircraftModels[i][2] + '" selected="selected">' + this.aircraftModels[i][1] + '</option>';
        } else {
          options += '<option value="' + this.aircraftModels[i][2] + '">' + this.aircraftModels[i][1] + '</option>';
        }
      }
    }
    $("#aircraft_model_id").html(options);
  }
}
