import { Controller } from "@hotwired/stimulus"
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

export default class extends Controller {
  connect() {
    const lightbox = new PhotoSwipeLightbox({
      gallery: this.element,
      children: 'a',
      mouseMovePan: true,
      initialZoomLevel: 'fit',
      secondaryZoomLevel: 2,
      maxZoomLevel: 4,
      clickToCloseNonZoomable: false,
      wheelToZoom: true,
      pswpModule: PhotoSwipe
    });

    // First load the image urls to get real size.
    $(this.element).find('a').each((index, link) => {
      const image = new Image();
      image.src = link.href;

      image.onload = () => {
        $(link).attr('data-pswp-width', image.width)
        $(link).attr('data-pswp-height', image.height)
      }
    });

    // Dynamically get the image dimensions.
    // lightbox.addFilter('domItemData', (itemData, element, linkEl) => {
    //   const image = new Image();
    //   image.src = itemData.src;
    //   itemData.width = image.width
    //   itemData.height = image.height

    //   return itemData;
    // });

    lightbox.init();
  }
}
