import { Controller } from "@hotwired/stimulus"
import Masonry from "masonry-layout"

export default class extends Controller {
  connect() {
    var imagesLoaded = require('imagesloaded');
    const selector = this.data.get('selector');

    imagesLoaded(selector, function() {
      new Masonry(selector, {
        itemSelector: '.item',
      });
    });
  }
}
